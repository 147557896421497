import ShowCongratsPopupActionController from "../FlowActionControllers/ShowCongratsPopupAction.controller";
import BaseFlowController from "./Baseflow.controller";

class Ftue1SubmitFlowController extends BaseFlowController {
  protected actions = [
    new ShowCongratsPopupActionController<Ftue1SubmitFlowController>(this, {}),
  ];

  constructor() {
    super();
  }
}

export default Ftue1SubmitFlowController;
