import { ScholarshipCourses } from "@/staticData/constant";
import ClientAppManager from "../../ClientAppManager";
import BaseFlowController from "./Baseflow.controller";
import NameFormPageActionController from "../FlowActionControllers/PageActionController/NameFormPageAction.controller";
import PhoneNumberFormPageActionController from "../FlowActionControllers/PageActionController/PhoneNumberFormPageAction.controller";
import ExperienceFormPageActionController from "../FlowActionControllers/PageActionController/ExperienceFormPageAction.controller";
import GamingExperienceFormPageActionController from "../FlowActionControllers/PageActionController/GamingExperienceFormPageAction.controller";
import JobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/JobCategoryFormPageAction.controller";
import PreferredJobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/PreferredJobCategoryFormPageAction.controller";
import IsWorkingFormPageActionController from "../FlowActionControllers/PageActionController/IsWorkingFormPageAction.controller";
import FresherJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/FresherJoinReasonFormPageAction.controller";
import ExperiencedJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/ExperiencedJoinReasonFormPageAction.controller";
import OriginPageWithBookCallPopupActionController from "../FlowActionControllers/PageActionController/OriginPageWithBookCallPopupAction.controller";

class JoinWaitlistFlowController extends BaseFlowController {
  protected actions = [
    new NameFormPageActionController<JoinWaitlistFlowController>(this),
    new PhoneNumberFormPageActionController<JoinWaitlistFlowController>(this),
    new ExperienceFormPageActionController<JoinWaitlistFlowController>(this),
    new GamingExperienceFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new JobCategoryFormPageActionController<JoinWaitlistFlowController>(this),
    new PreferredJobCategoryFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new IsWorkingFormPageActionController<JoinWaitlistFlowController>(this),
    new FresherJoinReasonFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new ExperiencedJoinReasonFormPageActionController<JoinWaitlistFlowController>(
      this
    ),
    new OriginPageWithBookCallPopupActionController<JoinWaitlistFlowController>(
      this
    ),
  ];

  constructor() {
    super();
  }

  override checkIfActionsNeeded(): boolean {
    const { waitlistedCourse } =
      ClientAppManager.instance.userFlowManager?.flowData || {};
    const isScholarshipCourse = ScholarshipCourses.includes(waitlistedCourse);

    if (isScholarshipCourse) {
      return false;
    }
    return true;
  }
}

export default JoinWaitlistFlowController;
