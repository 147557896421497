import globals from "../../../Globals";
import { Actions, Pages } from "../../Hooks/useAnalyticsClickEvent";
import { Analytics } from "analytics";

class AnalyticsManager {
  constructor() {
    this.previousUrl = "";
    this.linkAnalyticsData = {};
    this.debugMode = {};
    this.eventsMap = {};
    this.userData = null;
    this.userVisitStatus = 0;
    this.FOR_CLARITY = "CUSTOM_CLARITY_EVENT";
    if (
      (process.env.NEXT_PUBLIC_ENV !== undefined &&
        process.env.NEXT_PUBLIC_ENV !== "production") ||
      (process.env.REACT_APP_ENV !== undefined &&
        process.env.REACT_APP_ENV !== "production")
    ) {
      this.debugMode = { debug_mode: true };
    }
    this.analytics = Analytics({
      app: 'outscal',
    })
  }

  setOutscalAnalyticsPlugin = (plugin) => {
    this.analytics = Analytics({
      app: 'outscal',
      plugins: plugin ? [plugin()] : []
    })
  }

  #hasUsedGamingEngine = (techStack) => {
    let hasWorked =
      techStack.includes("unity") ||
      techStack.includes("unreal") ||
      techStack.includes("play-canvas") ||
      techStack.includes("cocos") ||
      techStack.includes("construct") ||
      techStack.includes("godot") ||
      techStack.includes("babylon") ||
      techStack.includes("phaser");
    return Number(hasWorked);
  };

  setUserProps = (user = false) => {
    if (window.gtag) {
      window.gtag("set", "user_properties", {
        persona: user && user.persona,
        user_id: user && user.user_id,
        isGamingBG: user && user.professional_background,
        experiance: user && user.experience,
        role: user && user.job_role,
        usedGameEngine:
          user && user.techStack && this.#hasUsedGamingEngine(user.techStack),
        personaHash: user && user.hashedPersona,
        // ...utms,
      });
    }
  };

  checkAuth = (auth) => {
    if (typeof localStorage !== "undefined" && typeof window !== "undefined") {
      let userDataStr =
        localStorage.getItem("userData");
      if ((auth && auth.token != null) || userDataStr) {
        this.userVisitStatus = 1;
        let userData = JSON.parse(userDataStr);
        this.userData = userData;
        this.analytics.identify(userData._id, userData)
        window.gtag &&
          window.gtag("config", globals.REACT_APP_GA_ID, {
            ...this.debugMode,
          });
        window.clarity &&
          window.clarity(
            "identify",
            userData.email,
            userData._id,
            window.location.href
          );
        window.clarity && window.clarity("set", "user_id", userData._id);
      } else {
        window.clarity &&
          window.clarity(
            "identify",
            "notSignedemail",
            "notSignedInId",
            window.location.href
          );
        window.gtag &&
          window.gtag("config", globals.REACT_APP_GA_ID, {
            ...this.debugMode,
          });
        window.clarity && window.clarity("set", "user_id", "notsignedin");
      }
    }
  };

  #trackPageViewEvent = (url, title) => {
    // window.gtag("event", "page_view", {
    //   page_title: title,
    //   page_location: url,
    // });
  };

  #trackClickEvent = ({ category, action, label, value, eventName }) => {
    window.gtag &&
      window.gtag("event", eventName, {
        category,
        action,
        label,
        value,
      });
    let showLabel = "";
    showLabel = label ? "label: " + label : "";
    window.clarity(
      "set",
      category || "Click",
      eventName + "_" + action + showLabel
    );
  };

  urlChanged = (location) => {
    const url = location.pathname + location.search;
    if (url === this.previousUrl) {
      this.previousUrl = url;
      this.#trackPageViewEvent(url);
    }
  };

  event = ({ category, label, eventName, action }) => {
    const value = this.userVisitStatus;
    const url = window.location.pathname + window.location.search;

    this.#trackClickEvent({
      action,
      category,
      label,
      value,
      eventName,
    });
  };

  clickEvent = (clickEvent, { category, label, eventName, action } = {}) => {
    const element = clickEvent.target;
    const tag = element.tagName;
    let text = element.innerText;
    let id = element.id;
    if (!text) {
      text =
        element.labels && element.labels.length > 0
          ? element.labels[0].innerText
          : "";
    }
    const value = this.userVisitStatus;
    if (!action) {
      action = "";
    }

    if (!eventName) {
      category = "Click";
      label = text;
      eventName = "UC";
      action = "";
    } else {
      if (!label) {
        label = text;
      }
      if (!action) {
        action = "";
      }
    }

    this.#trackClickEvent({
      action,
      category,
      label,
      value,
      eventName,
      type: "Click_Event",
    });
  };

  // new system

  #setClarityEvent = (page, UI, label, commonElement, CET_Name, action) => {
    // CET=CommonElementType

    // Page : CET_?UI_Label                                 Jobs-listing:Header_button_jobs, Jobs-Listing:Popup_button_accept ,Jobs-listing:card_jobs,  Jobs-listing:button_apply

    // CET
    // Page: CET_Name_Open  (only for popup)
    // CET_Name: UI_Label                                Header:button_jobs,  Popup_gameguild:button_accept

    //needed CET, UI, LABEL ,ACTION, CET_NAME
    //need to fetch pages
    console.log(page, UI, label, commonElement, CET_Name, action);
    let tag = page;
    let CET_Tag = commonElement ? commonElement + "_" : null;
    let value = (CET_Tag || "") + UI + "_" + label;

    window.clarity("set", tag, value);

    if (commonElement) {
      tag = commonElement + "_" + CET_Name;
      value = UI + "_" + label;

      window.clarity("set", tag, value);
    }

    if (CET_Name) {
      tag = page;
      value = commonElement + "_" + CET_Name + "_" + action;

      window.clarity("set", tag, value);
    }
  };

  #setGAEvent = (page, UI, label, commonElement, CET_Name, action) => {
    window.gtag &&
      window.gtag("event", label, {
        page,
        UI,
        label,
        commonElement,
        CET_Name,
        action,
      });
  };

  getPageKey = () => {
    var reProfile = /^\/profile\/[^\/]+\/?$/;
    var reProfilePost = /^\/profile\/[^\/]+\/[^\/]+$/i;
    var reProfilePostDetails = /^\/profile\/[^\/]+\/[^\/]+\/[^\/]+$/i;
    let pageKey = "NOT_FOUND";
    if (
      window?.location?.pathname?.split("/").length === 2 &&
      window?.location?.pathname?.split("/")[1] === ""
    ) {
      pageKey = Pages.LANDINGPAGE;
    } else if (window?.location?.pathname?.includes("/course/")) {
      pageKey = Pages.COURSE;
    } else if (window?.location?.pathname.includes("/company/")) {
      pageKey = Pages.COMPANY;
    } else if (window?.location?.pathname.includes("/companies")) {
      pageKey = Pages.COMPANIES;
    } else if (window?.location?.pathname.includes("/courses")) {
      pageKey = Pages.COURSES;
    } else if (
      window?.location?.pathname.includes("/jobs") &&
      !window?.location?.pathname.includes("/jobs/")
    ) {
      pageKey = Pages.JOBS;
    } else if (window?.location?.pathname.includes("/job/")) {
      pageKey = Pages.JOB;
    } else if (reProfile.test(window?.location?.pathname)) {
      pageKey = Pages.PROFILE;
    } else if (reProfilePost.test(window?.location?.pathname)) {
      pageKey = Pages.PROFILE_POST;
    } else if (reProfilePostDetails.test(window?.location?.pathname)) {
      pageKey = Pages.PROFILE_PROFILE_DETAILS;
    } else if (window?.location?.pathname.includes("/job-listing/")) {
      pageKey = Pages.STATIC_JOB_PAGE;
    } else if (window?.location?.pathname.includes("/welcome/")) {
      pageKey = Pages.WELCOME;
    } else if (window?.location?.pathname.includes("/success-stories")) {
      pageKey = Pages.SUCCESS_STORIES;
    } else if (window?.location?.pathname.includes("/videos")) {
      pageKey = Pages.VIDEOS;
    } else if (window?.location?.pathname?.includes("/daily-question")) {
      pageKey = Pages.DAILY_QUESTION;
    } else if (window?.location?.pathname?.includes("/practice")) {
      pageKey = Pages.PRACTICE;
    } else if (window?.location?.pathname?.includes("/assessment")) {
      pageKey = Pages.ASSESSMENT_PAGE;
    } else if (window?.location?.pathname?.includes("/job-applications")) {
      pageKey = Pages.JOB_APPLICATIONS;
    } else if (window?.location?.pathname?.includes("/invite")) {
      pageKey = Pages.INVITE;
    } else if (window?.location?.pathname?.includes("/bookings")) {
      pageKey = Pages.BOOKINGS_PAGE;
    } else if (window?.location?.pathname?.includes("/booking-details/")) {
      pageKey = Pages.BOOKING_DETAILS;
    } else if (window?.location?.pathname?.includes("/ads/") || window?.location?.pathname?.includes("/blog/")) {
      pageKey = Pages.ADS;
    } else if (window?.location?.pathname?.includes("/scholarships")) {
      pageKey = Pages.SCHOLARSHIPS;
    } else if (window?.location?.pathname?.includes("/scholarship")) {
      pageKey = Pages.SCHOLARSHIP;
    } else if (window?.location?.pathname?.includes("/mcqs")) {
      pageKey = Pages.MCQS;
    } else if (window?.location?.pathname?.includes("/online-compilers/")) {
      pageKey = Pages.ONLINE_COMPILERS;
    } else if (window?.location?.pathname?.includes("/action-plan")) {
      pageKey = Pages.ACTIONPLAN
    } else if (window?.location?.pathname?.includes("/form/")) {
      pageKey = Pages.FORMPAGE
    } else if (window?.location?.pathname?.includes("/discord")) {
      pageKey = Pages.DISCORD
    } else if (window?.location?.pathname?.includes("/blog/")) {
      pageKey = Pages.BLOG
    } else if (window?.location?.pathname?.includes("/community/")) {
      pageKey = Pages.COMMUNITY
    }
    if (
      window?.location?.pathname?.includes("/daily-question") &&
      window?.location?.pathname?.split("/").length >= 3
    ) {
      pageKey = Pages.DAILY_ALL_QUESTIONS;
    }
    if (
      window?.location?.pathname?.includes("/course/") &&
      window?.location?.pathname?.split("/").length > 3
    ) {
      pageKey = Pages.LMS;
    }
    return pageKey;
  };

  trackEvent = (page, UI, label, commonElement, CET_Name, action) => {
    this.#setClarityEvent(page, UI, label, commonElement, CET_Name, action);
    this.#setGAEvent(page, UI, label, commonElement, CET_Name, action);
    this.analytics.track("Click", { page, UI, label, commonElement, CET_Name, action, userData: this.userData })
  };

  actionEvent = (UI, label, commonElement, CET_Name, action) => {
    const page = this.getPageKey();
    this.#setClarityEvent(page, UI, label, commonElement, CET_Name, action);
    this.#setGAEvent(page, UI, label, commonElement, CET_Name, action);
    this.analytics.track(action, { page, UI, label, commonElement, CET_Name, action, userData: this.userData })
  };

  trackViewEvent = (UI, label) => {
    const page = this.getPageKey();
    let action = Actions.elementView;
    console.log("###_visible", UI, label);
    this.analytics.track(action, { page, UI, label, action, userData: this.userData })
  };
}

export default new AnalyticsManager();
