import {
  IntentMapType,
  IntentObjectType,
  UTMParamsType,
} from "./Service.types";

const IntentMap: IntentMapType = {
  course: "COURSE",
  job: "JOB",
  company: "COMPANY",
};

class UTMManager {
  public static instance: UTMManager;
  private _landingUtm: UTMParamsType = {};
  private _lastUtm: UTMParamsType = {};
  private _sessionUtm: UTMParamsType = {};
  private _landingIntent: IntentObjectType = {};
  private _sessionIntent: IntentObjectType = {};

  constructor() {
    this.initialize();
  }
  public static getInstance(): void {
    if (!this.instance) {
      this.instance = new this();
    }
  }

  private checkIfUtmSame(utm1: UTMParamsType, utm2: UTMParamsType): boolean {
    let same: boolean = true;
    const utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "utm_referrer_url",
    ];
    for (let ut of utmKeys) {
      if (utm1[ut] !== utm2[ut]) {
        same = false;
        break;
      }
    }

    return same;
  }

  private checkIfUtmEmpty(utm1: UTMParamsType): boolean {
    let empty: boolean = true;
    const utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];
    for (let ut of utmKeys) {
      if (utm1[ut] !== "") {
        empty = false;
        break;
      }
    }

    return empty;
  }

  private checkIfIntentEmpty(intent1: IntentObjectType): boolean {
    let empty: boolean = true;
    const utmKeys = ["intent", "url"];
    for (let ut of utmKeys) {
      if (intent1[ut] !== "") {
        empty = false;
        break;
      }
    }

    return empty;
  }

  private initialize(): void {
    this.initializeUtms();
    this.initializeIntents();
  }

  private initializeUtms(): void {
    let landingUtmParamsStr =
      localStorage.getItem("landingUtm") ||
      localStorage.getItem("firstLandingUtms");
    let lastUtmParamsStr = localStorage.getItem("lastUtm");
    let sessionUtmParamsStr = sessionStorage.getItem("sessionUtm");

    if (sessionUtmParamsStr) {
      this._sessionUtm = JSON.parse(sessionUtmParamsStr);
    } else {
      this._sessionUtm = this.getUtmsFromUrl();
      sessionStorage.setItem("sessionUtm", JSON.stringify(this._sessionUtm));
    }

    if (landingUtmParamsStr) {
      this._landingUtm = JSON.parse(landingUtmParamsStr);
    } else {
      this._landingUtm = this._sessionUtm;
      localStorage.setItem("landingUtm", JSON.stringify(this._landingUtm));
    }

    if (lastUtmParamsStr && this.checkIfUtmEmpty(this._sessionUtm)) {
      this._lastUtm = JSON.parse(lastUtmParamsStr);
    } else {
      this._lastUtm = this._sessionUtm;
      localStorage.setItem("lastUtm", JSON.stringify(this._lastUtm));
    }
  }

  private initializeIntents(): void {
    let landingIntentParamsStr = localStorage.getItem("landingIntent");
    let lastIntentParamsStr = localStorage.getItem("lastIntent");
    let sessionIntentParamsStr = sessionStorage.getItem("sessionIntent");

    if (sessionIntentParamsStr) {
      this._sessionIntent = JSON.parse(sessionIntentParamsStr);
    } else {
      this._sessionIntent = this.getCurrentIntent();
      sessionStorage.setItem(
        "sessionIntent",
        JSON.stringify(this._sessionIntent)
      );
    }

    if (landingIntentParamsStr) {
      this._landingIntent = JSON.parse(landingIntentParamsStr);
    } else {
      this._landingIntent = this._sessionIntent;
      localStorage.setItem(
        "landingIntent",
        JSON.stringify(this._landingIntent)
      );
    }
  }

  public getCurrentIntent(): IntentObjectType {
    let intent = "other";
    const pathname = window.location.pathname;
    const asPath = window.location.href;
    Object.keys(IntentMap).map((key) => {
      if (pathname.includes(key)) {
        intent = IntentMap[key];
      }
    });

    let updatedURL = new URL(asPath);

    // Loop through all utmParams in sessionUtmParams and add them to the URL if not already present
    Object.keys(this._sessionUtm).forEach((key) => {
      if (!updatedURL.searchParams.has(key) && this._sessionUtm[key]) {
        updatedURL.searchParams.append(key, this._sessionUtm[key]);
      }
    });

    const intentObj: IntentObjectType = { intent, url: updatedURL.toString() };
    return intentObj;
  }

  private getUtmsFromUrl(): UTMParamsType {
    let utms: UTMParamsType = {
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_term: "",
      utm_content: "",
      utm_referrer_url: "",
    };

    const queryParams = new URLSearchParams(window.location.search);
    utms["utm_referrer_url"] = document.referrer || "direct";
    const utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];

    utmKeys.forEach((key) => {
      const value = queryParams.get(key);
      if (value) {
        utms[key] = value;
      }
    });

    return utms;
  }

  public get landingUtm(): UTMParamsType {
    return this._landingUtm;
  }

  public get lastUtm(): UTMParamsType {
    return this._lastUtm;
  }

  public get sessionUtm(): UTMParamsType {
    return this._sessionUtm;
  }

  public get landingIntent(): IntentObjectType {
    return this._landingIntent;
  }

  public get sessionIntent(): IntentObjectType {
    return this._sessionIntent;
  }
}

export default UTMManager;
