import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class FresherJoinReasonFormPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/form/fresher-joining-reason";
  public override checkAction(): boolean {
    return ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
      ?.user_form_data?.course_joining_reason == undefined &&
      ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
        ?.gaming_job_experience == 0
      ? true
      : false;
  }
}

export default FresherJoinReasonFormPageActionController;
