import ClientBotTrackingService from "@/Logic/ClientLogic/Services/AnalyticsUtils/ClientBotTrackingService";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";

function trackOnES(page, UI, label, commonElement, CET_Name, action, userData, metaData) {
    if ((window.location.href.includes("outscal.com") || window.location.href.includes("outscal.io"))) {
        let data = {
            key: "asertuklnbfdrtyujhngfghjknbgh",
            page, UI, label, commonElement, CET_Name, action: action || "Click",
            pageUrl: window.location.href,
            email: userData?.email,
            id: userData?._id,
            referrer: document.referrer,
            metaData,
            userBehaviour: ClientBotTrackingService.instance.getInfo()
        }

        fetch("/api/es", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
}

function formatAnalyticsData(data) {
    const now = new Date();
    let sessionId = "on_server"
    if (typeof sessionStorage !== "undefined") {
        sessionId = sessionStorage.getItem("session_id")
        if (sessionId) {
        } else {
            let time = new Date();
            sessionId = Date.now() + "_" + time.toISOString();
            sessionStorage.setItem("session_id", sessionId)
        }
    }
    let { page, UI, label, commonElement, CET_Name, action, userData } = data.properties;
    let metaData = {
        ...data,
        sessionId,
        properties: undefined,
        eventTime: now.toISOString(),
        landingUtm: UTMManager.instance.landingUtm,
        sessionUtm: UTMManager.instance.sessionUtm,
        lastUtm: UTMManager.instance.lastUtm,
        landingIntent: UTMManager.instance.landingIntent,
        sessionIntent: UTMManager.instance.sessionIntent,
    }
    trackOnES(page, UI, label, commonElement, CET_Name, action, userData, metaData)
}


export default function OutscalCustomPlugin(userConfig) {
    // return object for analytics to use
    let latitude = "";
    let longitude = "";
    let device = "";
    let timeZone = "";
    let browser = "";
    let userAgent = "";
    return {
        name: 'OutscalCustomPlugin',
        initialize: ({ config }) => {
            if (typeof navigator != "undefined") {
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                navigator?.geolocation.getCurrentPosition(function (location) {
                    latitude = location.coords.latitude
                    longitude = location.coords.longitude
                });
                userAgent = navigator?.userAgent.toLowerCase();
                if (/android/.test(userAgent)) {
                    device = "Android";
                } else if (/iphone/.test(userAgent)) {
                    device = "iPhone";
                } else if (/ipad/.test(userAgent)) {
                    device = "iPad";
                } else if (/ipod/.test(userAgent)) {
                    device = "iPod";
                } else if (/tablet/.test(userAgent)) {
                    device = "Tablet";
                } else {
                    device = "Desktop";
                }

                if (/chrome|crios/.test(userAgent)) {
                    browser = "Chrome";
                } else if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
                    browser = "Safari";
                } else if (/firefox/.test(userAgent)) {
                    browser = "Firefox";
                } else if (/opr|opera/.test(userAgent)) {
                    browser = "Opera";
                } else if (/msie|trident/.test(userAgent)) {
                    browser = "Internet Explorer";
                } else {
                    browser = "Unknown Browser";
                }
            }
        },
        page: ({ payload }) => {
        },
        track: ({ payload }) => {
            formatAnalyticsData({ ...payload, timeZone, location: { latitude, longitude }, device, browser, userAgent })
        },
        identify: ({ payload }) => {
        },
        loaded: () => {
            return true
        }
    }
}


