import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class JobCategoryFormPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/form/job-category";
  public override checkAction(): boolean {
    return ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
      ?.job_category == undefined &&
      ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
        ?.experience > 0
      ? true
      : false;
  }
}

export default JobCategoryFormPageActionController;
