import { NextRouter } from "next/router";
import BaseFlowController from "./FlowControllers/Baseflow.controller";
import Ftue1SubmitFlowController from "./FlowControllers/Ftue1SubmitFlowController";
import JoinWaitlistFlowController from "./FlowControllers/JoinWaitlistFlow.controller";
import { FlowDataType, FLOWTYPES } from "./UserFlowService.types";
import ReturningWaitlistedUserFlowController from "./FlowControllers/ReturningWaitlistedUserFlow.controller";
import PopupService from "../PopupService";

class UserFlowManager {
  public currentFlow: BaseFlowController;
  public flowData: FlowDataType;
  public router: NextRouter;
  constructor() {}

  private initiateFlow(flow: BaseFlowController, flowData: FlowDataType): void {
    this.currentFlow = flow;
    this.flowData = flowData;
    this.currentFlow.takeAction();
  }

  public checkAndTriggerFlow(
    flowName: FLOWTYPES,
    flowData?: FlowDataType
  ): void {
    let flow = null;

    if (flowName == FLOWTYPES.JOIN_WAITLIST) {
      localStorage.setItem(
        "prev-url",
        PopupService.instance.removePopupQueryFromUrl(this.router.asPath)
      );
      flow = JoinWaitlistFlowController;
    } else if (flowName == FLOWTYPES.RETURNING_JW_USER) {
      localStorage.setItem(
        "prev-url",
        PopupService.instance.removePopupQueryFromUrl(this.router.asPath)
      );
      flow = ReturningWaitlistedUserFlowController;
    } else if (flowName == FLOWTYPES.FTUE1_SUBMIT) {
      flow = Ftue1SubmitFlowController;
    }

    if (flow) {
      this.initiateFlow(new flow(), flowData);
    }
  }

  public endFlow(): void {
    this.currentFlow = null;
    this.flowData = null;
  }
}

export default UserFlowManager;
