import { UserType } from "./Service.types";

class UserDetailsManager {
  public user: UserType;
  public isInitialised: boolean = false;
  private detailsInitialised: {
    profile: boolean;
    userDetails: boolean;
    userCourseStatuses: boolean;
    userPreference: boolean;
  } = {
    profile: false,
    userDetails: false,
    userCourseStatuses: false,
    userPreference: false,
  };

  private initialise(user: UserType): void {
    if (!this.detailsInitialised.profile && user?.userJobProfile) {
      this.detailsInitialised.profile = true;
    }

    if (!this.detailsInitialised.userPreference && user?.userPreference) {
      this.detailsInitialised.userPreference = true;
    }

    if (
      !this.detailsInitialised.userCourseStatuses &&
      user?.userCourseStatuses
    ) {
      this.detailsInitialised.userCourseStatuses = true;
    }

    if (!this.detailsInitialised.userDetails && user.userDetails) {
      this.detailsInitialised.userDetails = true;
    }

    if (
      this.detailsInitialised.profile &&
      this.detailsInitialised.userCourseStatuses &&
      this.detailsInitialised.userDetails
    ) {
      this.isInitialised = true;
    }
  }

  public setUserData(user: UserType): void {
    if (!this.isInitialised) {
      this.initialise(user);
    }
    this.user = { ...this.user, ...user };
  }

  public uninitialised(): void {
    this.isInitialised = false;
    this.detailsInitialised = {
      profile: false,
      userCourseStatuses: false,
      userDetails: false,
      userPreference: false,
    };
  }
}

export default UserDetailsManager;
