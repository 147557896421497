class PopupService {
  public showPopup: (popup: number, data: any) => {};
  public hidePopup: () => {};
  public static instance: PopupService;

  private constructor() {}

  public static getInstance(): void {
    if (!this.instance) {
      this.instance = new this();
    }
  }

  public initialisePopupAction(
    showPopup: (popup: number, data: any) => {},
    hidePopup: () => {}
  ): void {
    this.showPopup = showPopup;
    this.hidePopup = hidePopup;
  }

  public removePopupQueryFromUrl = (url: string) => {
    const [baseUrl, queryString] = url?.split("?");
    if (!queryString) {
      return url;
    }

    const params = queryString?.split("&")?.filter((param) => {
      return !param.startsWith(`${"popup"}=`);
    });

    return params?.length > 0 ? `${baseUrl}?${params.join("&")}` : baseUrl;
  };
}

export default PopupService;
