import ClientAppManager from "../../ClientAppManager";
import BaseActionController from "../FlowActionControllers/BaseAction.controller";

class BaseFlowController {
  private nextAction: number = 0;
  protected actions: BaseActionController<BaseFlowController>[] = [];

  protected checkIfActionsNeeded(): boolean {
    return true;
  }

  public takeAction(): void {
    const areActionsNeeded = this.checkIfActionsNeeded();

    if (!areActionsNeeded) {
      return ClientAppManager.instance.userFlowManager?.endFlow();
    }
    let hasTakenAction = false;
    let checkingAction = this.nextAction;
    do {
      let potentialAction = this.actions[checkingAction];
      hasTakenAction = potentialAction.checkAndTakeAction();
      checkingAction++;
      this.nextAction = checkingAction;
    } while (!hasTakenAction && checkingAction < this.actions.length);

    if (this.nextAction >= this.actions.length) {
      ClientAppManager.instance.userFlowManager?.endFlow();
    }
  }
}

export default BaseFlowController;
