import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class ExperienceFormPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/form/experience";
  public override checkAction(): boolean {
    return ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
      ?.experience == undefined ||
      ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
        ?.experience == null
      ? true
      : false;
  }
}

export default ExperienceFormPageActionController;
