type JobListViewedPlug = {
  [key: number]: number[];
};

class JobPlugsManager {
  public jobListViewedPlugs: JobListViewedPlug = {};
  public addViewedPlug(interval: number, priority: number): void {
    if (!this.jobListViewedPlugs[interval]) {
      this.jobListViewedPlugs[interval] = [];
    }

    if (!this.jobListViewedPlugs[interval].includes(priority)) {
      this.jobListViewedPlugs[interval].push(priority);
    }
  }

  public getPlugs() {
    return this.jobListViewedPlugs;
  }
}

export default JobPlugsManager;
