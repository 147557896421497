import { useEffect } from "react";
import { useRouter } from "next/router";

const useScrollBarVisibility = (pathsToShow = [], pathsToExclude = []) => {
    const router = useRouter();

    const doesPathMatch = (path, paths) => {
        const regexPatterns = paths.map(pattern =>
            new RegExp(
                "^" +
                pattern
                    .replace(/\*/g, ".*")
                    .replace(/\//g, "\\/")
                + "$"
            )
        );
        return regexPatterns.some(regex => regex.test(path));
    };

    useEffect(() => {
        const { asPath } = router;

        if (doesPathMatch(asPath, pathsToExclude)) {
            document.body.classList.remove('show-scrollbar');
        } else if (doesPathMatch(asPath, pathsToShow)) {
            document.body.classList.add('show-scrollbar');
        } else {
            document.body.classList.remove('show-scrollbar');
        }
    }, [router.pathname, pathsToShow, pathsToExclude]);
};

export default useScrollBarVisibility;
