const returnDisplayList = (
  optionsList,
  label = "display_name",
  id = "slug",
  otherKey = [],
  alternateLable = "name"
) => {
  return optionsList?.map((option) => {
    let obj = {
      id: option[id],
      label: option[label] || option[alternateLable],
    };
    if (otherKey.length) {
      for (let key of otherKey) {
        obj[key] = option[key];
      }
    }
    return obj;
  });
};

const returnDisplayListOfCountries = (countriesList) => {
  return countriesList.map((country) => {
    return {
      id: country.abbreviation,
      label: country.country,
    };
  });
};

export default { returnDisplayList, returnDisplayListOfCountries };
