import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class PhoneNumberFormPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/form/phone-number";
  public override checkAction(): boolean {
    return !ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
      ?.phone_number
      ? true
      : false;
  }
}

export default PhoneNumberFormPageActionController;
