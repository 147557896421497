export enum FLOWTYPES {
  JOIN_WAITLIST,
  RETURNING_JW_USER,
  RETURNING_JW_USER_COURSE,
  FTUE1_SUBMIT,
}

export type FlowDataType = {
  waitlistedCourse?: string;
};
