import React from "react";
import {
  CloseIcon,
  EditSkillsCta,
  EditProfileDiv,
  PopupWrapper,
} from "./AddSkillPopup.styles";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import PopupTemplate from "@/outscal-commons-frontend/Styled/PopupTemplate";
import { ColDiv, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { YellowButton } from "@/outscal-commons-frontend/Styled";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { skillAssessmentMapping } from "@/staticData/Plugs/skillAssessments";
import AddSkillCard from "@/mvcComponents/CommonUI/Cards/AddSkillCard/AddSkillCard.controller";

const AddSkillPopupView = ({
  hidePopups,
  heading: skillPopupHeading = "",
  editProfileText = "",
  editProfileLink = "/",
  optionsList: jobTechStackList = [],
  userTechStack: userTechStackList = [],
  assessmentResponses = {},
  skillSet,
  setUserSkillStates,
}) => {
  return (
    <PopupTemplate
      setShowPopUp={() => {
        hidePopups();
      }}
      maxWidth="500px"
      width="95%"
      bgColor="white"
      height="fit-content"
      overflow="hidden"
      id="add-skill-popup"
    >
      <PopupWrapper gap="18px" padding="20px">
        <RowDiv alignItems="center" justifyContent="space-between">
          <Text fontSize="xl" fontWeight="semiBold">
            {skillPopupHeading}
          </Text>

          <CloseIcon
            fontSize="24px"
            onClick={hidePopups}
            data-analytics={[UIElements.BUTTON, `close_addSkillPopup`]}
          />
        </RowDiv>
        <ColDiv gap="8px">
          {jobTechStackList?.map(
            ({ id: skillId = "", label: skillLabel = "" }) => (
              <AddSkillCard
                key={skillId}
                techStack={{ id: skillId, label: skillLabel }}
                assessmentResponse={
                  skillAssessmentMapping[skillLabel]
                    ? assessmentResponses[
                        skillAssessmentMapping[skillLabel].assessment_id
                      ]
                    : null
                }
                skillSet={skillSet}
                setUserSkillStates={setUserSkillStates}
              />
            )
          )}
        </ColDiv>
        <EditProfileDiv alignItems="center" gap="4px">
          <Text fontSize="sm">{editProfileText}</Text>
          <EditSkillsCta
            data-analytics={[UIElements.TEXT, "edit_skills_text"]}
            href={editProfileLink}
            target="_blank"
          >
            Edit Skills
          </EditSkillsCta>
        </EditProfileDiv>
        <RowDiv justifyContent="flex-end">
          <YellowButton
            padding="6px 18px"
            onClick={hidePopups}
            data-analytics={[UIElements.BUTTON, `done_addSkillPopup`]}
          >
            Done
          </YellowButton>
        </RowDiv>
      </PopupWrapper>
    </PopupTemplate>
  );
};

export default AddSkillPopupView;
