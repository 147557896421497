import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class IsWorkingFormPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/form/is-working";
  public override checkAction(): boolean {
    return ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
      ?.user_form_data?.is_currently_working == undefined ||
      ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
        ?.user_form_data?.is_currently_working == null
      ? true
      : false;
  }
}

export default IsWorkingFormPageActionController;
