import { BOOK_CALL_POPUP } from "@/store/actions/popupAction";
import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BaseActionController from "../BaseAction.controller";
import PopupService from "../../../PopupService";
import BasePageActionController from "./BasePageAction.controller";

class OriginPageWithBookCallPopupActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = localStorage.getItem("prev-url");
  public async takeAction(): Promise<void> {
    const prevUrl = this.url;
    if (prevUrl == ClientAppManager.instance.userFlowManager.router.asPath) {
      PopupService.instance.showPopup(BOOK_CALL_POPUP, {});
    } else {
      await ClientAppManager.instance.userFlowManager.router.push({
        pathname: this.url,
        query: {
          popup: BOOK_CALL_POPUP,
        },
      });
    }
    return Promise.resolve();
  }
}

export default OriginPageWithBookCallPopupActionController;
