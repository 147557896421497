import { MESSAGE_POPUP } from "@/store/actions/popupAction";
import PopupService from "../../PopupService";
import BaseFlowController from "../FlowControllers/Baseflow.controller";
import BaseActionController from "./BaseAction.controller";
import { AskFillWaitlistFormActionType } from "./FlowActionControllers.types";
import ClientAppManager from "../../ClientAppManager";

class AskFillWaitlistFormPopupActionController<
  T extends BaseFlowController
> extends BaseActionController<T> {
  private actionData: AskFillWaitlistFormActionType;
  constructor(flow: T, actionData: AskFillWaitlistFormActionType) {
    super(flow);
    this.actionData = actionData;
  }

  public checkAction(): boolean {
    let haveAskedOnce = sessionStorage.getItem("askFillWaitlistFormOpened");

    const userDetails = ClientAppManager.instance.userDetailsManager.user;
    const { userJobProfile, userPreference } = userDetails;

    const isRequiredDataPresent =
      userJobProfile?.name &&
      userJobProfile?.experience != null &&
      userJobProfile?.experience >= 0 &&
      userJobProfile?.gaming_job_experience != null &&
      userJobProfile?.gaming_job_experience >= 0 &&
      userJobProfile?.phone_number &&
      userJobProfile?.user_form_data?.is_currently_working != undefined &&
      (userJobProfile?.job_category || userPreference?.job_category) &&
      userJobProfile?.user_form_data?.course_joining_reason;

    return !isRequiredDataPresent && !haveAskedOnce ? true : false;
  }

  public takeAction(): void {
    sessionStorage.setItem("askFillWaitlistFormOpened", "true");
    PopupService.instance.showPopup(MESSAGE_POPUP, {
      ...this.actionData,
      action: () => {
        this.actionCallBack();
      },
      onCloseByUser: () => {
        ClientAppManager.instance.userFlowManager.endFlow();
      },
    });
  }
}

export default AskFillWaitlistFormPopupActionController;
