import React, { useState } from "react";
import AddSkillPopupView from "./AddSkillPopup.view";
import usePopupActions from "@/store/actions/popupAction";
import useTechStackActions from "@/store/actions/techStackAction";
import { skillsPopupData } from "./AddSkillPopup.model";
import { sortUSersSkillsByState } from "@/Services/skillAssessments";

const AddSkillPopup = ({ data = {} }) => {
  const { assessmentResponses, skillSet } = data;
  const { hidePopups } = usePopupActions();
  const { techStackList } = useTechStackActions();
  const [userSkillStates, setUserSkillStates] = useState(skillSet);

  const createIdLabelArray = (stringArray, techStackList) => {
    return stringArray.map((element) => {
      const matchedObject = techStackList.find((obj) => obj?.label === element);
      return matchedObject;
    });
  };

  const sortedSkills = sortUSersSkillsByState({
    skillSet,
    userAssessmentResponses: assessmentResponses,
  });

  return (
    <AddSkillPopupView
      hidePopups={hidePopups}
      heading={skillsPopupData.heading}
      editProfileText={skillsPopupData.editProfileText}
      editProfileLink={skillsPopupData.editProfileLink}
      optionsList={createIdLabelArray(sortedSkills, techStackList)}
      skillSet={userSkillStates}
      assessmentResponses={data.assessmentResponses}
      setUserSkillStates={setUserSkillStates}
    />
  );
};

export default AddSkillPopup;
