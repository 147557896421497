import AskFillWaitlistFormPopupActionController from "../FlowActionControllers/AskFillWaitlistFormPopupAction.controller";
import ExperiencedJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/ExperiencedJoinReasonFormPageAction.controller";
import ExperienceFormPageActionController from "../FlowActionControllers/PageActionController/ExperienceFormPageAction.controller";
import FresherJoinReasonFormPageActionController from "../FlowActionControllers/PageActionController/FresherJoinReasonFormPageAction.controller";
import GamingExperienceFormPageActionController from "../FlowActionControllers/PageActionController/GamingExperienceFormPageAction.controller";
import IsWorkingFormPageActionController from "../FlowActionControllers/PageActionController/IsWorkingFormPageAction.controller";
import JobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/JobCategoryFormPageAction.controller";
import NameFormPageActionController from "../FlowActionControllers/PageActionController/NameFormPageAction.controller";
import PhoneNumberFormPageActionController from "../FlowActionControllers/PageActionController/PhoneNumberFormPageAction.controller";
import PreferredJobCategoryFormPageActionController from "../FlowActionControllers/PageActionController/PreferredJobCategoryFormPageAction.controller";
import OriginPageWithBookCallPopupActionController from "../FlowActionControllers/PageActionController/OriginPageWithBookCallPopupAction.controller";
import BaseFlowController from "./Baseflow.controller";
import { AskFillWaitlistFormData } from "./FlowControllers.model";
import ClientAppManager from "../../ClientAppManager";
import { CALENDLY_ADMISSION_BOOKING_POPUP } from "@/store/actions/popupAction";

class ReturningWaitlistedUserFlowController extends BaseFlowController {
  protected actions = [
    new AskFillWaitlistFormPopupActionController<ReturningWaitlistedUserFlowController>(
      this,
      AskFillWaitlistFormData
    ),
    new NameFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new PhoneNumberFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new ExperienceFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new GamingExperienceFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new JobCategoryFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new PreferredJobCategoryFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new IsWorkingFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new FresherJoinReasonFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new ExperiencedJoinReasonFormPageActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
    new OriginPageWithBookCallPopupActionController<ReturningWaitlistedUserFlowController>(
      this
    ),
  ];

  protected checkIfActionsNeeded(): boolean {
    return ClientAppManager.instance.userFlowManager.router.asPath.includes(
      `popup=${CALENDLY_ADMISSION_BOOKING_POPUP}`
    )
      ? false
      : true;
  }
}

export default ReturningWaitlistedUserFlowController;
