import MobileDetect from "mobile-detect";
type DeviceInfoType = {
  deviceType: string;
  deviceName: string;
  os: string;
  isUAIdentifiedBot: boolean;
};
type MousePos = {
  x: number;
  y: number;
};
type UserBehaviourType = {
  hasMouseMoved: MousePos;
  lastKeyPress: string;
  deviceInfo: DeviceInfoType;
};

export default class ClientBotTrackingService {
  public static instance: ClientBotTrackingService;
  private lastKeyPress: string = "none";
  public hasMouseMoved: MousePos = { x: -1, y: -1 };
  private deviceInfo: DeviceInfoType;
  private botRegex =
    /(bot|crawl|slurp|spider|curl|google|yahoo|bing|duckduckgo|baidu|msn|sogou|yandex|exabot|facebookexternalhit)/i;
  private constructor() {
    this.initialize();
  }
  public static getInstance(): void {
    if (!this.instance) {
      this.instance = new this();
    }
  }

  private mouseMoved = (event: MouseEvent): void => {
    this.hasMouseMoved = { x: event.clientX, y: event.clientY };
  };

  private onKeyPress = (event: KeyboardEvent): void => {
    this.lastKeyPress = event.key;
  };

  public getInfo(): UserBehaviourType {
    return {
      hasMouseMoved: this.hasMouseMoved,
      lastKeyPress: this.lastKeyPress,
      deviceInfo: this.deviceInfo,
    };
  }

  private initialize = (): void => {
    if (typeof document !== "undefined") {
      document.addEventListener("keydown", this.onKeyPress);
      document.addEventListener("mousemove", this.mouseMoved);
      let md = new MobileDetect(window.navigator.userAgent);
      this.deviceInfo = {
        deviceType: md.mobile() ? "Mobile" : md.tablet() ? "Tablet" : "Desktop",
        deviceName: md.mobile() || md.tablet() || "Desktop",
        os: md.os(),
        isUAIdentifiedBot: this.botRegex.test(window.navigator.userAgent),
      };
    }
  };
}
