import ClientAppManager from "../../../ClientAppManager";
import BaseFlowController from "../../FlowControllers/Baseflow.controller";
import BasePageActionController from "./BasePageAction.controller";

class PreferredJobCategoryFormPageActionController<
  T extends BaseFlowController
> extends BasePageActionController<T> {
  protected url = "/form/preferred-category";
  public override checkAction(): boolean {
    return ClientAppManager.instance.userDetailsManager?.user?.userPreference
      ?.job_category == undefined &&
      ClientAppManager.instance.userDetailsManager?.user?.userJobProfile
        ?.experience == 0
      ? true
      : false;
  }
}

export default PreferredJobCategoryFormPageActionController;
