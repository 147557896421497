import BaseFlowController from "../FlowControllers/Baseflow.controller";

class BaseActionController<T extends BaseFlowController> {
  protected myFlow: T;
  constructor(flow: T) {
    this.myFlow = flow;
  }

  public checkAndTakeAction(): boolean {
    if (this.checkAction()) {
      this.takeAction();
      return true;
    }
    return false;
  }

  protected checkAction(): boolean {
    return true;
  }

  protected takeAction(): void {}

  protected actionCallBack(): void {
    this.myFlow.takeAction();
  }
}

export default BaseActionController;
